.menu {
    background: rgba(0,0,0,1);
    height: 100%;
    display: flex;
}

.bm-menu {
    padding-top: 50px;
}

.bm-item {
    text-decoration: none;
    font-size: 1.2em;
    color: white;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 30px;
    height: 24px;
    right: 16px;
    top: 23px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    padding: 3em 1em 0;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.5em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    margin-top: 15px;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 1);
  }

@media only screen and (min-width: 720px) {
  
}