.mintPage {
  background: #111;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.animation-container {
    margin-top: 40px;
    border: 2px solid white;
    width: 80%;
    max-width: 700px;
    max-height: 700px;
}

#mint-animation { 
    width: 100%;
    height: 100%;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
}

#mint-container {
  background: #555;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: white;
}

#mint-slider {
  width: 80%;
  margin: 10px 0;
}

#mint-number {
  font-size: 1.8em;
  color: white;
  margin-top: 10px;
}

#mint-button {
  width: 150px;
  background: #EF3340;
  color: white;
  border-radius: 5px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 20px 0;
  cursor: pointer;
  font-family: Raleway;
  font-weight: 600;
  text-align: center;
}

.bad-chain-id {
  color: red;
  width: 80%;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
}

.confirmation-modal {
  width: 90%;
  max-width: 500px;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  bottom: 40px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  border-radius: 10px;
  text-align: center;
}

.confirmation-modal h3 {
  color: white;
  font-weight: 900;
  font-size: 2.5em;
}

.confirmation-modal p {
  color: white;
  font-size: 1.5em;
}

.modal-buttons {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
}

.modal-btn {
  margin-top: 10px;
  background: #eee;
  border-radius: 10px;
  text-align: center;
  width: 100px;
  padding: 10px;
  cursor: pointer;
}

.modal-btn a {
  text-decoration: none;
  color: inherit;
  width: 100%;
  padding: 30px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 720px) {
  #mint-container {
    width: 80%;
    border-radius: 5px;
    border: 2px solid white;
  }
}

@media only screen and (min-width: 1024px) {
    #mint-container {
      width: 40%;
      border-radius: 5px;
      border: 2px solid white;
      margin-bottom: 40px;
      max-width: 500px;
    }

    .animation-container {
      margin-top: 40px;
      border: 2px solid white;
      width: 40%;
      max-width: 500px;
      max-height: 500px;
  }
}
