.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
}

.login-btn {
    width: 150px;
    background: white;
    color: black;
    text-align: center;
    border-radius: 10px;
    padding: 10px 0;
    cursor: pointer;
}