#footer {
    width: 100%;
    height: 70px;
    min-height: 70px;
    background: #111;
    border-top: 2px solid white;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

#footer h1 {
    color: white;
}

#footer-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    width: 70%;
}

#opensea-icon {
    width: 2.5em;
}

@media only screen and (min-width: 1024px) {
    #footer-icons {
        width: 50%;
    }
}