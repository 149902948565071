.header {
    height: 70px;
    min-height: 70px;
    background: #111;
    display: flex;
    width: 100%;
    border-bottom: 2px solid white;
    margin-bottom: 5px;
    font-family: Raleway;
    font-weight: 600;
}

.connected-text {
    color: white;
    font-size: 1em;
    background: #310000;
    padding: 0 10px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    top: 15px;
    left: 15px;
    position: absolute;
}

#burger {
    display: block;
}

#nav {
    display: none;
}

@media only screen and (min-width: 1024px) {
    #burger {
        display: none !important;
    }

    .bm-burger-button {
        display: none;
    }
    
    #nav {
        display: flex;
        width: 400px;
        min-width: 400px;
        max-width: 800px;
        justify-content: flex-end;
        margin-right: 40px;
    }

    #nav ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        justify-content: space-between;
        width: 70%;
        color: white;
    }

    #nav ul a {
        text-decoration: none;
        color: white;
    }

    .header {
        justify-content: space-between;
        align-items: center;
    }
}