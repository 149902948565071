.redeem-list-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
}

.redeem-list-container {
    height: 90%;
}