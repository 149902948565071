#tokenList {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    font-family: Raleway;
    max-width: 600px;
}

.tokens {
    width: 90%;
    border: 1px solid white;
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #333;
}

.redeem-token {
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

#white-token {
    border: 3px solid white;
}

#gold-token {
    border: 3px solid gold;
}

.token-number {
    color: white;
    font-size: 1.2em;
}

.redeem-btn {
    background: #EF3340;
    padding: 10px;
    border-radius: 5px;
    font-weight: 600;
    color: white;
    cursor: pointer;
}

@media only screen and (min-width: 720px) {
    #tokenList {
        width: 70%;
    }

    .tokens {
        width: 75%;
    }

    .token-number {
        font-size: 1.5em;
    }
}

@media only screen and (min-width: 1024px) {
    #tokenList {
        width: 60%;
        max-width: 600px;
    }

    .tokens {
        width: 75%;
    }

    .token-number {
        font-size: 1.5em;
    }
}