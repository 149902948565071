.redeemPage {
    background: #111;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#modal-dialog span {
    cursor: pointer;
}

#video-container {
    width: 80%;
    max-width: 700px;
    max-height: 700px;
}

#redeem-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    overflow-y: auto;
    height: 100%;
}

#redeem-main #mint-button {
    text-align: center;
    padding: 50px;
}

.redeem-token-anim-container {
    width: 100%;
    border: 2px solid white;
    max-width: 700px;
    max-height: 700px;
}

.redeem-token-anim-container video, .redeem-token-anim-container source {
    width: 100%;
    height: 100%;
    max-width: 700px;
    max-height: 700px;
}

.redeem-confirmation-modal {
    width: 90%;
    background: #310000;
    font-family: Raleway;
    font-weight: 600;
    font-size: 1em;

    max-width: 500px;
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    bottom: 40px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    border-radius: 10px;
    text-align: center;
}

#modal-dialog {
    width: 80%;
    height: 80%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#modal-dialog h1, #modal-dialog p {
    text-align: center;
    margin-bottom: 30px;
}

#modal-dialog p {
    font-size: 1em;
}

#redeem-modal-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
    font-size: 0.8em;
    font-family: Raleway;
    font-weight: 600;
}

.redeem-modal-btn {
    background: #EF3340;
    width: 100px;
    min-width: 80px;
    height: 75%;
    padding: 10px;
    margin: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

#redeem-modal-btns a {
    text-decoration: none;
    color: white;
}

@media only screen and (min-width: 768px) {

    .redeem-confirmation-modal {
        width: 60%;
        font-family: Raleway;
        font-weight: 600;
        font-size: 1.2em;
    }

    #video-container {
        width: 60%;
        max-width: 700px;
        max-height: 700px;
    }

    .reveal-token-anim-container {
        width: 40%;
        max-width: 700px;
        max-height: 700px;
    }

    #reveal-anim {
        width: 100%;
        max-width: 700px;
        max-height: 700px;
    }

    #reveal-anim-source {
        width: 100%;
        max-width: 700px;
        max-height: 700px;
    }
}