.redeemForm {
    width: 90%;
    max-width: 500px;
    background: #fefefa;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Raleway;
    font-weight: 600;
    overflow-y: scroll;
}

#redeem-form {
    width: 100%;
    height: 100%;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px;
}

form p {
    color: black;
}

.error {
    color: red;
}

label {
    color: white;
    font-size: 1em;
    margin-top: 5px;
}

input {
    font-size: 1em;
    padding: 5px;
    margin-bottom: 20px;
}

i {
    margin-bottom: 20px;
    color: #EF3340;
    margin-top: -20px;
}

select {
    font-size: 1em;
    margin-bottom: 10px;
    padding: 5px;
}

#connect-btn {
    width: 150px;
}

#submit-btn {
    width: 100%;
    height: 60px;
    font-size: 1.4em;
    background: #EF3340;
    color: white;
    font-family: Raleway;
    font-weight: 600;
    border-radius: 10px;
}

@media only screen and (min-width: 1024px) {
    .redeemForm {
        width: 40%;
    }
}