.redeem-item {
    display: flex;
    flex-direction: row;
    border: 1px solid white;
    width: 90%;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    padding: 30px;
}

.redeem-item img {
    width: 200px;
    height: 200px;
    margin-right: 50px;
}

.redeem-item-info {
    flex: 1;
}